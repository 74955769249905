@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=caslon:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: caslon;
  src: url(./caslon-antique-regular.ttf);
}
body {
  font-style: normal;
  background: #000000;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0 !important;
  min-height: 100vh;
  font-family: caslon!important;
}

.main {
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  background: #000000;
}
.topsection{
  background: url(backtop.png) center top #000000;
  min-height: calc(100vh - 62px);
  position: relative;
}
.shadow{
  background: linear-gradient(transparent, #000000 50%);
  height: 40vh;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.logofull{
  width: 35%;
  padding-top: 75px;
  margin-inline: auto;
  display: block;
}
nav{
  height: 62px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}
nav img{
 
  height: 50px;
  width: auto;
}

.main-content {
  display: flex;
  justify-content: center;
  width: 70%;
  margin-inline: auto;
  margin: auto;
  align-items: center;
  flex-direction: row-reverse;
  position: relative;
  margin-top: 20px;
}

.vertical-main-containerr {
  display: grid;
}

.containerr {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-flow: column nowrap;
  padding: 30px;
  border-radius: 8px;
  color: #fff;
  width: 40%;
}

.minted {
  margin-bottom: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.452);
  font-family: caslon;
}

.progress {
  text-align: left;
  width: 100%;
}

select {
  display: block;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23242835%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 1rem top 0.9rem, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0.4rem 2rem 0.4rem 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border: 1px solid #6370e5;
  border-radius: 8px;
  line-height: 1.5;
  color: #6370e5;
  transition: all 200ms ease-in-out;
  font-size: 0.889rem;
}

.containerr .network-select {
  flex-flow: column;
  margin-bottom: 0.85rem;
}

.network-select label {
  font-size: 0.8rem;
}

div.account-center-actions {
  flex-direction: column;
}

div.account-center-actions>div {
  margin: 8px;
}
.inner-box-wrapper{
  z-index: 1000;
}

.bn-demo-button {
  background: #ffffffee;
  font-size: 0.889rem;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 0.55rem 1.4rem;
  color: #6370e5;
  margin: 0.4rem 0.75rem;
  transition: background 200ms ease-in-out, color 200ms ease-in-out;
}

.bn-demo-button:focus {
  outline: none;
}

.bn-demo-button:hover {
  cursor: pointer;
}

.selected-toggle-btn {
  background: #6370e5;
  color: white;
}

.unselected-toggle-btn {
  color: #6370e5;
  background: #ffffffee;
}

.ui-settings {
  text-align: center;
}

.ui-settings .conditional-ui-settings {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1375px) {
  .bn-logo-link {
    position: initial;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  body {
    overflow: unset;
    overflow-x: hidden;
  }

  main {
    height: unset;
    overflow: unset;
  }

  section {
    flex-direction: column;
  }

  div.containerr {
    width: unset;
  }
}

@media screen and (max-width: 770px) {
  .main-content {
    flex-direction: column;
    align-items: center;
  }

  div.containerr {
    padding: 1rem;
    margin-bottom: 2rem;
    width: calc(100vw - 4rem);
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .containerr .send-transaction-containerr {
    flex-direction: column;
    margin: 4px auto;
  }
}

.minting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.mintbutton {
  background: black;
  color: white;
  border-radius: 5px;
  font-size: 30px;
  padding: 10px 80px;
  font-weight: 800;
  cursor: pointer;
  border: 2px solid #4cff00;
  transition: 0.1s all ease-in-out;
  margin-inline: auto;
  display: block;
  font-family: caslon;
  font-weight: 100;
}

.mintbutton:hover {
  box-shadow: 1px 1px 20px #4cff00;
  background: transparent;
  padding: 10px 90px;
  border-radius: 10px;
}



.MuiLinearProgress-bar {
  border-radius: 30px !important;
  background: #4cff00 !important;
}

.containerr h2 {
  text-align: center;
  font-family: caslon;
  
  font-weight: 100;
  font-size: 50px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.loadscr {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loadscr img {
  width: 30%;
}

@keyframes moveout {
  0% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
    display: none;
  }
}

.cost h3 {
  font-family: caslon;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  margin-top: 5px;

  margin-bottom: 5px;

}
.labelwl{
  width: 200px;
  text-align: center;
  background: black;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  padding: 5px 10px;
  font-weight: 800;
  user-select: none;
  border: 2px solid #4cff00;
}
.cost h3 span {
  font-family: caslon;
  font-size: 20px;
  font-weight: 100;
}

.cost h2 {
  font-family: caslon;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.452);
  margin-bottom: 0px;
  text-align: left;
  font-weight: 400;
}

.quantitymint {
  display: flex;
  justify-content: space-between;
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.226);
  padding: 10px;
  align-items: center;
  border-radius: 7px;
}

.quantitymint h2 {
  font-family: caslon;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.671);
  font-weight: 400;
  margin-bottom: 0px !important;
}
.welcome-header{
  font-size: 48px;
  text-align: center;
  font-weight: 400;
  text-align: center;
  color: #e7ebee;
  position: relative;
}
input {
  background: transparent;
  border: none;
  font-family: Luckiest Guy, cursive;
  font-size: 25px;
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.address,
.owner {
  color: white;
}


#logout {
  display: none;
}



.quantitymint {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  padding-top: 30vh;
}

.modal-backdrop.show {
  opacity: .75;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 98vw;
  height: 100vh;
  background-color: #000;

}

.fade {
  transition: opacity .15s linear;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-header {
  display: flex;
  overflow-x: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #adadad;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  overflow-x: hidden;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #adadad;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: rgb(211, 211, 211);
  background-clip: padding-box;
  border: 2px solid rgb(8, 8, 8);
  border-radius: .3rem;
  outline: 0;
  font-family: caslon;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary {
  color: #000;
  background-color: yellow;
  border-color: yellow;
  font-family: caslon;
  font-weight: 600;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-family: caslon;
  font-weight: 800;
  font-size: 25px;
}

.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.quantitybuttons {
  display: flex;
  flex-direction: column;
}

.arrowup {
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 6px 8px;
  height: 0;
  margin-bottom: 2px;
  width: 0;
  opacity: 0.7;
  cursor: pointer;
}

.arrowdown {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 8px 6px 0;
  height: 0;
  margin-top: 2px;
  width: 0;
  cursor: pointer;
}

.phases {

  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: auto;
  margin-bottom: 30px;


}

.phase {
  font-family: caslon;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  font-weight: 700;
  width: 100px;
  text-align: center;
  cursor: pointer;
  opacity: 0.2;
}

.active {
  color: white;
  background: #4cff00;
  border: 2px solid #4cff00;
  opacity: 1;
}

.date {
  border: 1px solid #4cff00;
  border-radius: 5px;
  color: #4cff00;
  padding: 3px 10px;
  font-family: caslon;
  font-size: 16px;
  margin-bottom: 5px;
}

.dates {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px !important;
  text-align: center;

}

.datetext {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.452);
  font-family: caslon;
}


.wlcheckwrap {
  display: flex;
  flex-direction: column;
}

.wlcheckwrap h3 {
  font-weight: 300;
  font-family: caslon;
  margin-top: 5px;
  font-size: 16px;
}

.container {
  padding: 2em;
  padding-top: 100px;

  margin: 0 auto;
  max-width: 1300px;
  padding-bottom: 120px;
}

.timeline {
  position: relative;
  min-height: var(--margin-section);
  width: 80%;
  margin-inline: auto;
}


.line {
  position: absolute;
  z-index: 2;
  left: calc(50% - 1px);
  width: 2px;
  top: -50px;
  bottom: -50px;
  background: #e7ebee;
  display: none;
}

.line:before {
  position: absolute;
  display: block;
  content: '';
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #e7ebee;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
}

.line:after {
  position: absolute;
  display: block;
  content: '';
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #e7ebee;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
}

.line:after {
  bottom: 0;

}

.section {
  display: flex;
  opacity: 0.5;
  transition: 600ms ease;
  position: relative;
  z-index: 1;
  border-radius: 1rem;
  align-items: center;
  min-height: 300px;
  justify-content: flex-end;
  margin-bottom: 150px;
}

.section img {
  width: 100px;
}




.bead {
  position: absolute;
  display: block;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: #e7ebee;
  left: 50%;
  top: 50%;
  opacity: 0.1;
  transition: 1000ms;
  transform: translateX(-50%);
}

.show-me:nth-child(n) .bead {
  opacity: 1;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  flex-direction: row-reverse;
}
.rev{
  flex-direction: row;
}
.content img{
  height: 320px;
  width: auto;
  border-radius: 5px;
}
.col{
  display: flex;
  flex-direction: column;
}
.section .content{
  width: 100%;
  margin: 10px;
}
.content h4, .community h2 {
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  font-family: caslon;
  color: #e7ebee;
  margin-bottom: 12px;
  margin-top: 0px;

}
.content p{
  font-size: 24px;
  font-family: caslon;
  text-align: center;
  color: #e7ebee;
  width: 320px;
}
.community p{
  font-size: 24px;
  font-family: caslon;
  text-align: center;
  color: #e7ebee;
  max-width: 570px;
  margin-inline: auto;
}
.show-me:nth-child(n) {
  transform: none;
  opacity: 1;
}
.community{
  background: linear-gradient(#000000, #131b1b);
  padding-bottom: 100px;
}

@media(max-width: 800px) {
  .main-content {
    width: 100%;
  }

  .imageleft {
    width: 80%;
  }

  .imageleft img {
    width: 100%;
  }

  .containerr h2 {
    font-size: 30px;
  }

  .phases {
    width: 100%;
    justify-content: center;
  }

  .cost h3 {
    font-size: 30px;
    text-align: left;
  }

  .quantitymint h2 {
    font-size: 10px;
  }

  .main {
    overflow-y: visible;
  }



  .main-content {
    justify-content: center;
    gap: 20px;

  }
  .containerr{
    padding-bottom: 20px;
  }

  .imageleft {
    width: 310px;
  }

  @keyframes clipMeMobile {

    0%,
    100% {
      clip: rect(0px, 330px, 2px, 0px);
    }

    25% {
      clip: rect(0px, 2px, 330px, 0px);
    }

    50% {
      clip: rect(328px, 330px, 330px, 0px);
    }

    75% {
      clip: rect(0px, 330px, 330px, 328px);
    }
  }

  .imageleft::before,
  .imageleft::after {
    content: "";
    z-index: -1;
    margin: -2%;
    color: #4cff00;
    box-shadow: inset 0 0 0 2px;
    animation: clipMeMobile 8s linear infinite;
  }
  .imageleft::before {
    animation-delay: -4s;
  }
  *{
    overflow-y: visible;
  }
  .imageleft{
    margin-top: 20px;
  }
  .dates{
    margin-bottom: 20px!important;
  }
  .containerr{
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
  .logofull{
    width: 80%;
  }
  .welcome-header{
    font-size: 26px;
  }
  .content{
    flex-direction: column;
  }
  .line{
    left: 0;
  }
  .container{
    padding: 10px;
    padding-top: 100px;
  }
  .timeline{
    width: 95%;
  }
  .bead{
    left: 0;
  }
  .content img{
    width: 90%;
    height: auto;
  }
  .content h4, .community h2{
    font-size: 30px;
    margin-bottom: 0px;
  }
  .content p{
    font-size: 20px;
    max-width: 260px;
  }
  .community p{
    font-size: 20px;
    max-width: 90%;
  }
  .content{
    gap: 20px;
  }
}